<template>
  <div v-if="mode=='edit'">
    <b-row v-if="render">
      <b-col md="4">
        <b-row>
          <b-col md="12">      
            <b-form-group label="Imagen">                          
              <Imagen :images="block.control.image" 
                      @load-image="block.control.image = $event" 
                      :typeImage="'Imagen_' + dataIndex + '_' + block.control.title"
                      :multipleImage="true" 
                      :compressImage="true"/>                      
            </b-form-group>  
          </b-col>
        </b-row>
      </b-col>
      <b-col md="8">
        <b-row>                   
          <b-col md="12">
            <b-form-checkbox v-model="block.control.active" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              Activo
            </b-form-checkbox>            
          </b-col>      
          <b-col md="12">
            <b-form-group label="Tipo Vista">
              <b-form-select v-model="block.control.typeView" 
                             :options="arr.select.typeView"
                             size="sm">
              </b-form-select>
            </b-form-group>          
          </b-col>                          
          <b-col md="12">      
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.control.title"                          
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>        
          <b-col md="12">      
            <b-form-group label="Slug" description="Si se deja vacio, se crea automaticamente tomando como referencia el título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.control.slug"                          
                            placeholder="Ingresar un slug">
              </b-form-input>
            </b-form-group>
          </b-col>                               
          <b-col md="12">
            <b-form-group label="Intro">
              <b-form-textarea                                
                v-model="block.control.intro"
                size="sm"
                placeholder="Ingresa la intro del contenido"
              ></b-form-textarea>                  
            </b-form-group>
          </b-col>            
          <b-col md="12">      
            <b-form-group label="Descripción">
              <vue-editor v-model="block.control.description"></vue-editor>              
            </b-form-group>
          </b-col>  
        </b-row>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div v-if="render">      
      <b-list-group-item class="flex-column align-items-start">

        <div class="d-flex w-100 justify-content-between">          
          <h5 class="mb-1">Item - {{block.control.title}}</h5>              
        </div>
      
      </b-list-group-item>    
    </div>
  </div>
</template>
<script>
  import Imagen from '@/components/inc/image/image'
  import { VueEditor } from "vue2-editor";
  import moment from 'moment'
  import Funciones from '@/handler/funciones'

  export default {
    components: {      
      Imagen,
      VueEditor
    },  
    props:{
      dataBlock:{
        type: Object,
        default: {}
      },   
      dataIndex:{
        type: Number,
        default: 0,
        required: true,
      },               
      controlTemporal: {
        type: [Object, Array],
      },      
      mode:{
        type: String,
        default: 'list'
      }         
    },
    data: () => {
      return {   
        block: {
          control: [],
        },             
        render: false,    
        arr: {
          select: {
            typeView: []
          }
        }                    
      }
    },
    watch: {       
      'block.control.title': function (newQuestion, oldQuestion) {
        var title = newQuestion + "_" + (parseInt(this.dataIndex) + 1)

        if(! this.block.control.slug) {
          this.block.control.slug = Funciones.slugify(title)
        }    
      },
      'block.control.slug': function (newQuestion, oldQuestion) {
        var title = this.block.control.title + "_" + (parseInt(this.dataIndex) + 1)
                
        if(! this.block.control.slug) {
          this.block.control.slug = Funciones.slugify(title)
        }    
      }
    },       
    created() {         
      if(!this.dataBlock) {
        this.block.control = {                     
          active: false,
          title: '',
          intro: '',
          description: '',       
          image: [],   
          typeView: '',
          slug: '',
        }
        
        this.emit()
        this.render = true
      }
      else {             
        var img = []  
        var i = 0                 
        if(this.getControlTermporal.image) {          
          for (i; i < this.getControlTermporal.image; i++) {        
            if(this.getControlTermporal["image_" + i]) {
              img.push({ path: this.getControlTermporal["image_" + i] })
            }
          }          
        }

        this.block.control= {
          active: this.dataBlock.active,          
          title: this.dataBlock.title,
          intro: this.dataBlock.intro,
          description: this.dataBlock.description,          
          image: img,  
          typeView: this.dataBlock.typeView,
          slug: this.dataBlock.slug,
        }
        
        this.emit()
        this.render = true
      }
    },
    mounted() {
      this.arr.select.typeView.push({
        value: 'simple',
        text: 'Simple'
      })
      this.arr.select.typeView.push({
        value: 'minimalist',
        text: 'Minimalista'
      })
    },
    computed: {
      getControlTermporal() {
        if(!this.controlTemporal) {
          return this.dataBlock
        } else {
          return this.controlTemporal
        }        
      }
    },    
    methods: {
      emit() {        
        this.$emit('get-object', this.block.control) 
        this.$emit('get-object-control-temporal', this.getControlTermporal) 
      },
    }    
  }
</script>