import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerForms() {
    var url = ConfigAPI.baseURL + "forms" + Session.getToken();
    return instance.get(url);
  },
  
  obtenerMoneda() {
    var url = ConfigAPI.baseURL + "price-currency" + Session.getToken();
    return instance.get(url);
  },

  obtenerRoles() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);
  },   

  filtrarProductoDestacado() {
    var params = null    
    var url = ConfigAPI.baseURL + "products/filter/featured" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerCategoria() {
    var url = ConfigAPI.baseURL + "products-category" + Session.getToken();
    return instance.get(url);
  },

  obtenerCategoriaConSubcategorias() {
    var url = ConfigAPI.baseURL + "products-category/find/categoriesWithSubcategories" + Session.getToken();
    return instance.get(url);
  },
  
  mostrarCategoria(id) {
    var url = ConfigAPI.baseURL + "products-category/" + id + Session.getToken();
    return instance.get(url);
  },

  mostrarSubcategoria(id) {
    var url = ConfigAPI.baseURL + "products-subcategory/" + id + Session.getToken();
    return instance.get(url);
  },
}

export default servicesAPI;
