<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>
          <b-col>
            <b-alert show variant="warning">
              La carga de productos se realiza desde el módulo de productos. 
              <b-link @click="goProducts()">Ir a productos</b-link>
            </b-alert>

            <hr>
          </b-col>
        </b-row>
        <b-tabs card>
          <b-tab title="Renderizado" active>
            <b-card-text>
              <b-row>   
                <b-col md="12">  
                  <b-form-group label="Imagen de Busqueda" description="En el proceso de busqueda se muestras las siguientes imagenes: 1) Imagen de <Buscando> | 2) Imagen <Sin Resultado>">
                    <Imagen :images="block.control.imageSearch" 
                            @load-image="block.control.imageSearch = $event" 
                            typeImage="ImagenSearch"
                            :multipleImage="true"
                            :compressImage="true"/>
                  </b-form-group>                    
                </b-col>

                <b-col md="12">  
                  <b-form-group label="Imagen de Categoría [Todos]" description="Imagen que renderiza el item [Todos] los productos">
                    <Imagen :images="block.control.imageCategoryAll" 
                            @load-image="block.control.imageCategoryAll = $event" 
                            typeImage="imageCategoryAll"
                            :multipleImage="false"
                            :compressImage="true"/>
                  </b-form-group>                    
                </b-col>                
              </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.title"
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Subtítulo">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.subtitle"
                            placeholder="Ingresar un subtítulo">
              </b-form-input>
            </b-form-group>
          </b-col>            
          <b-col md="12">
            <b-form-group label="Color de Texto">
              <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>    
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group> 
          </b-col> 
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col>            
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>  

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col>           

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>
  import Imagen from '@/components/inc/image/image'  
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {      
      PrismEditor,   
      Imagen,      
    },     
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},  
        render: false,         
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = {          
          title: '',
          imageSearch: [],  
          imageCategoryAll: [],
        },
        this.block.config = {
          title: '',
          subtitle: '',          
          fontColor: '#000000',
          backgroundColor: '#f5f5f5',
          backgroundImage: [],
          havePaddingBottom: true,
          havePaddingTop: true,  
          stylesCSS: '',        
        }
        this.block.images = ['imageSearch','imageCategoryAll'],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {                  
        var img = []  
        var img1 = []  
        var i = 0     
        var image = 100        
        if(image) {          
          for (i; i < image; i++) {    
            if(this.dataBlock.control["imageSearch_" + i]) {                    
              img.push({ path: this.dataBlock.control["imageSearch_" + i] })
            }

            if(this.dataBlock.control["imageCategoryAll_" + i]) {                    
              img1.push({ path: this.dataBlock.control["imageCategoryAll_" + i] })
            }            
          }          
        } 

        this.block.control= {
          title: 'Products Marketplace',          
          imageSearch: img,         
          imageCategoryAll: img1,         
        }

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {     
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }                              
          }          
        } 

        this.block.config= { 
          title: this.dataBlock.config.title,
          subtitle: this.dataBlock.config.subtitle,
          fontColor: this.dataBlock.config.fontColor,
          backgroundColor: this.dataBlock.config.backgroundColor,  
          backgroundImage: imgConfg,
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,         
          stylesCSS: this.dataBlock.config.stylesCSS, 
        }

        this.block.images = ['imageSearch','imageCategoryAll']
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    methods: {
      emit() {                        
        this.$emit('get-object', this.block) 
      },      
      goProducts() {
        this.$router.push({ name: 'ProductsStaffCrudProducts' })
      },
    }        
  }
</script>