<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Información </strong>
        </div>
        
        <b-row>
          <b-col md="12">
            <b-alert show variant="info">
              Podrá visualizar todas las consultas recibidas desde el módulo de Formularios.
              <b-link @click="goForms()">Ir a Formularios</b-link>
            </b-alert>            
          </b-col>
        </b-row>        
      </b-card>

      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>                      
          <b-col md="12">
            <b-form-group label="Formulario">
              <b-form-select v-model="block.control.formsID" :options="arr.select.forms"></b-form-select>
            </b-form-group>          
          </b-col> 
          <b-col md="12">               
            <hr>
          </b-col> 
          <b-col md="12">
            <b-form-group label="Contenido" description="Contenido que se muestra al lado del formulario de contacto">
              <b-form-select v-model="block.control.typeContent" 
                             :options="arr.select.typeContent"
                             @input="changeTypeContent()">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="block.control.typeContent=='maps'">
            <b-row>                
              <b-col md="6">      
                <b-form-group label="Latitud">
                  <b-form-input type="text"
                                size="sm"
                                v-model="block.control.lat"                          
                                placeholder="Ingresar latitud">
                  </b-form-input>
                </b-form-group>
              </b-col> 
              <b-col md="6">      
                <b-form-group label="Longitud">
                  <b-form-input type="text"
                                size="sm"
                                v-model="block.control.lng"                          
                                placeholder="Ingresar longitud">
                  </b-form-input>
                </b-form-group>
              </b-col> 
            </b-row>
          </b-col>
          <b-col md="12" v-if="block.control.typeContent=='html'">
            <b-row>               
              <b-col md="12">      
                <b-form-group label="HTML">
                  <b-form-textarea                                
                    v-model="block.control.html"
                    rows='6'
                    size="sm"
                  ></b-form-textarea>                  
                </b-form-group>                
              </b-col> 
            </b-row>
          </b-col>
        </b-row>        
      </b-card>      
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.title"
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Subtítulo">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.subtitle"
                            placeholder="Ingresar un subtítulo">
              </b-form-input>
            </b-form-group>
          </b-col>            
          <b-col md="12">
            <b-form-group label="Color de Texto">
              <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>    
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group>  
          </b-col> 
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col>            
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>   

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col> 

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>
  import Imagen from '@/components/inc/image/image'  
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {  
      PrismEditor,       
      Imagen,      
    },     
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},  
        render: false,
        arr: {
          select: {
            forms: [],
            typeContent: [
              {value:'', text:'Sin Contenido'},
              {value:'maps', text:'Mapa'},
              {value:'html', text:'HTML'},
            ]
          }
        }        
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = {
          title: '',
          formsID: 0,
          typeContent: '',
          lat: '',
          lng: '',        
          html: '',  
        },
        this.block.config = {
          title: '',
          subtitle: '',
          fontColor: '#000000',
          backgroundColor: '#ffffff',
          backgroundImage: [],
          havePaddingBottom: true,
          havePaddingTop: true,          
          stylesCSS: '',
        }
        this.block.images = [],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {                             
        this.block.control= {
          title: 'Forms',
          formsID: this.dataBlock.control.formsID,
          typeContent: this.dataBlock.control.typeContent,
          lat: this.dataBlock.control.lat,
          lng: this.dataBlock.control.lng,          
          html: this.dataBlock.control.html,
        }

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {     
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }                              
          }          
        } 

        this.block.config= { 
          title: this.dataBlock.config.title,
          subtitle: this.dataBlock.config.subtitle,
          fontColor: this.dataBlock.config.fontColor,
          backgroundColor: this.dataBlock.config.backgroundColor,  
          backgroundImage: imgConfg,
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,       
          stylesCSS: this.dataBlock.config.stylesCSS,             
        }

        this.block.images = []
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    mounted() {
      let loader = this.$loading.show();    
      var result = serviceAPI.obtenerForms()

      result.then((response) => {         
        var data = response.data
        
        data.forEach(element => {
          this.arr.select.forms.push({
            value: element.id,
            text: element.name
          })
        });
         
        loader.hide()   
      })
      .catch(error => {                   
        loader.hide()                 
        this.$awn.alert(Error.showError(error))
      });             
    },
    methods: {
      emit() {        
        this.$emit('get-object', this.block) 
      },
      goForms() {
        this.$router.push({ name: 'FormsStaffDashboard' })
      },    
      changeTypeContent() {
        if(this.dataBlock.control.typeContent == '') {
          this.dataBlock.control.lat = ''
          this.dataBlock.control.lng = ''
          this.dataBlock.control.html = ''
        }        
        if(this.dataBlock.control.typeContent == 'maps') {
          this.dataBlock.control.html = ''
        }
        if(this.dataBlock.control.typeContent == 'html') {
          this.dataBlock.control.lat = ''
          this.dataBlock.control.lng = ''          
        }        
      }  
    }        
  }
</script>