<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>
          <b-col>
            <b-alert show variant="warning">
              La carga de productos destacados se realiza desde el módulo de productos. 
              <b-link @click="goProducts()">Ir a productos</b-link>
            </b-alert>            

            <hr>
          </b-col>
        </b-row>

        <b-tabs card>
          <b-tab title="Opciones" active>
            <b-card-text>
              <b-row>   
                <b-col md="12">            
                  <b-form-group label="Opciones de Visualización">
                    <b-form-radio-group
                      v-model="block.control.typeView"
                      :options="optTypeView"
                      stacked
                      @input="changeView()"
                    ></b-form-radio-group>
                  </b-form-group>            
                </b-col>
                <b-col md="12">            
                  <hr>
                </b-col>
                <b-col md="12" v-if="block.control.typeView=='carousel_simple'">
                  <b-form-group label="Vistas" description="Vistas disponibles para visualizar en los filtros">
                    <b-form-radio-group v-model="block.control.viewCarousel" stacked @input="setShowSubcategorys()">
                      <b-form-radio value="view_feacture">Productos destacados</b-form-radio>
                      <b-form-radio value="view_news_products">Nuevos Ingresos</b-form-radio>
                      <b-form-radio value="view_more_sales">Más Vendidos</b-form-radio>
                      <b-form-radio value="view_offers">Ofertas Especiales</b-form-radio>
                      <b-form-radio value="view_subcategories">Subcategorías</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>                  
                </b-col>
                <b-col md="12" v-if="block.control.typeView=='grid_simple'">
                  <b-form-group label="Vistas" description="Vistas disponibles para visualizar en los filtros">
                    <b-form-checkbox v-model="block.control.viewFeacture"
                                      switch 
                                      size="sm">
                      Productos destacados
                    </b-form-checkbox>
                    <b-form-checkbox v-model="block.control.viewNewsProducts" 
                                      switch 
                                      size="sm">
                      Nuevos Ingresos
                    </b-form-checkbox>
                    <b-form-checkbox v-model="block.control.viewMoreSales" 
                                      switch
                                      size="sm">
                      Más Vendidos
                    </b-form-checkbox>
                    <b-form-checkbox v-model="block.control.viewOffers"
                                      switch
                                      size="sm">
                      Ofertas Especiales
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                
                <!-- SELECCIONAR SUBCATEGORIAS -->
                <b-col md="12" v-if="showSubcategorys">
                  <b-form-group label="Subcategorías">
                    <v-select :options="arr.select.subcategorys" v-model="subcategorysView" placeholder="Subcategorías" :multiple="true" :select-on-tab="true" @input="saveSubcategorys"></v-select>
                  </b-form-group>
                </b-col>
                <!-- FIN SELECCIONAR SUBCATEGORIAS -->

                <b-col md="12" v-if="block.control.typeView=='featured_center'">
                  <b-form-group label="Producto Central" description="De los primeros cinco (5) productos destacados, se muestra uno (1) en el centro">
                    <v-select :options="arr.select.productsFeatured" 
                              v-model="value.select.productsFeatured" 
                              placeholder="Productos Destacados" 
                              :multiple="false" 
                              :select-on-tab="true" 
                              @input="loadProductFeatured">
                    </v-select>                   
                  </b-form-group>
                </b-col>                  
                <b-col md="12">            
                  <hr>
                </b-col>
                <b-col md="6">
                  <b-form-group label="URL Botón">
                    <b-form-input type="url"
                                  size="sm"
                                  v-model="block.control.button_url"                          
                                  placeholder="Ingresar la url del botón">
                    </b-form-input>
                  </b-form-group>
                </b-col>                              
                <b-col md="6">
                  <b-form-group label="Texto Botón">
                    <b-form-input type="text"
                                  size="sm"
                                  v-model="block.control.button_text"                          
                                  placeholder="Ingresar el texto del botón">
                    </b-form-input>
                  </b-form-group>                                
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>        
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.title"
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Subtítulo">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.subtitle"
                            placeholder="Ingresar un subtítulo">
              </b-form-input>
            </b-form-group>
          </b-col>  

          <b-col md="12">
            <b-form-group label="Color de Texto">
              <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>    
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group> 
          </b-col> 
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col>            
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>  

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col>           

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>
  import serviceAPI from './../services'
  import Imagen from '@/components/inc/image/image'  
  import PrismEditor from 'vue-prism-editor'
  import Functions from '@/handler/funciones' 
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {   
      PrismEditor,      
      Imagen,      
    },     
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},  
        render: false,    
        optTypeView: [
          { text: 'Vista Carousel', value: 'carousel_simple' },
          { text: 'Grilla Simple', value: 'grid_simple' },
          { text: 'Destacado Central', value: 'featured_center' },
        ],
        arr:{
          select: {
            productsFeatured: [],
            subcategorys: [],
          }
        },
        value:{
          select: {
            productsFeatured: null
          }
        },
        subcategorysView: [],
        showSubcategorys: false
      }
    },   
    created() {
      if(!this.dataBlock.control) {
        this.block.control = {
          title: '',
          typeView: 'carousel_simple',
          productCenterID: 0,
          viewFeacture: true,
          viewNewsProducts: false,
          viewMoreSales: false,
          viewOffers: false,   
          viewSubcategories: false,   
          viewCarousel: 'view_feacture',
          button_url: '',
          button_text: '',
          subcategorys: [],
        },
        this.block.config = {
          title: '',
          subtitle: '',          
          fontColor: '#000000',
          backgroundColor: '#ffffff',
          backgroundImage: [],
          havePaddingBottom: true,
          havePaddingTop: true,          
          stylesCSS: '',
        }
        this.block.images = [],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {
        this.block.control= {          
          title: 'Products Featured',
          typeView: this.dataBlock.control.typeView,
          productCenterID: this.dataBlock.control.productCenterID,
          viewFeacture: this.dataBlock.control.viewFeacture,
          viewNewsProducts: this.dataBlock.control.viewNewsProducts,
          viewMoreSales: this.dataBlock.control.viewMoreSales,
          viewOffers: this.dataBlock.control.viewOffers,
          viewSubcategories: this.dataBlock.control.viewSubcategories,
          viewCarousel: this.dataBlock.control.viewCarousel,
          button_url: this.dataBlock.control.button_url,
          button_text: this.dataBlock.control.button_text,
          subcategorys: this.dataBlock.control.subcategorys,
        }

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {     
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }                              
          }          
        } 

        this.block.config= { 
          title: this.dataBlock.config.title,
          subtitle: this.dataBlock.config.subtitle,          
          fontColor: this.dataBlock.config.fontColor,
          backgroundColor: this.dataBlock.config.backgroundColor,  
          backgroundImage: imgConfg,
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,    
          stylesCSS: this.dataBlock.config.stylesCSS,      
        }

        this.block.images = []
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    mounted() {
      this.getProductFeatured()
      this.setShowSubcategorys()
      this.filterLoadSubcategorys()
    },
    methods: {
      getProductFeatured() {
        var result = serviceAPI.filtrarProductoDestacado()        
        result.then((response) => {          
          var data = response.data        

          data.forEach(element => {                     
            if(this.block.control.productCenterID) {              
              if(element.id == this.block.control.productCenterID) {
                this.value.select.productsFeatured = {code: element.id, label: element.name}   
                this.block.control.productCenterID = element.id
              }
            }    

            this.arr.select.productsFeatured.push({code: element.id, label: element.name})                                                                      
          });  
        })        
      },
      loadProductFeatured(val) {
        if(val) {
          this.block.control.productCenterID = val.code
        }
      },
      changeView() {
        if(this.block.control.typeView=='carousel_simple') {
          this.block.control.productCenterID = 0
        }

        this.setShowSubcategorys()
      },
      setShowSubcategorys() {
        this.showSubcategorys = false

        if(this.block.control.typeView == 'carousel_simple'){
          if(this.block.control.viewCarousel == 'view_subcategories') {
            this.showSubcategorys = true
          }
        }

        if(this.block.control.typeView == 'grid_simple'){
          this.showSubcategorys = false
        }

        if(this.block.control.typeView == 'featured_center'){
          this.showSubcategorys = false
        }
      },
      emit() {
        this.$emit('get-object', this.block) 
      },
      goProducts() {
        this.$router.push({ name: 'ProductsStaffCrudProducts' })
      }, 
      filterLoadSubcategorys() {
        this.arr.select.subcategorys = []
        
        var result = serviceAPI.obtenerCategoria()

        result.then((response) => {
          var data = response.data    

          data.forEach(element => {                      
            element.subcategorys.forEach(element1 => {
              this.arr.select.subcategorys.push({code: element1.id, label: element.name + ' - ' + element1.name})
            });   
          });

          var strSubcategorys = []
          if(this.dataBlock.control.subcategorys) {
            strSubcategorys = this.dataBlock.control.subcategorys.split(",")          
          }
          
          var arrSubcategorys = []
          strSubcategorys.forEach(element => {
            arrSubcategorys.push(Functions.selectArrayItemByField(this.arr.select.subcategorys,element,'code'))            
          });

          this.subcategorysView = arrSubcategorys
        })
      },
      saveSubcategorys(val) {
        var subcategorys_ids = []
        val.forEach(element => {
          subcategorys_ids.push(element.code)
        });
        
        this.block.control.subcategorys = subcategorys_ids
      },
    }        
  }
</script>