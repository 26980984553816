<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>
          <b-col>
            <b-alert show variant="warning">
              La carga de productos se realiza desde el módulo de productos. 
              <b-link @click="goProducts()">Ir a productos</b-link>
            </b-alert>
            
            <hr>
          </b-col>
        </b-row>

        <b-tabs card>
          <b-tab title="Opciones" active>
            <b-card-text>
              <b-row>   
                <b-col md="12">            
                  <b-form-group label="Opciones de Visualización">
                    <b-form-radio-group
                      v-model="block.control.typeView"
                      :options="optTypeView"
                      stacked
                      @input="changeView()"
                    ></b-form-radio-group>
                  </b-form-group>            
                </b-col>
                <b-col md="12">            
                  <b-form-group label="Tipo de Operaciones">
                    <b-form-radio-group
                      v-model="block.control.typeTransaction"
                      :options="optTypeTransaction"
                      stacked
                      @input="changeType()"
                    ></b-form-radio-group>
                  </b-form-group>            
                </b-col>          
              </b-row>

              <b-row>
                <b-col md="12">
                  <hr>
                </b-col>                          
                <b-col md="12">
                  <h5>General</h5>
                </b-col>  
              </b-row>
              <b-row>         
                <b-col md="12">
                  <b-form-group label="Precios">
                      <b-form-checkbox                  
                        v-model="block.control.showPriceWithIva"
                        switch
                      >
                        ¿Mostrar precios con IVA incluido?
                      </b-form-checkbox>
                  </b-form-group>            
                </b-col>
              </b-row>

              <b-row v-if="showOptionCurrency">                
                <b-col md="12">
                  <b-form-group label="Moneda" description="Moneda en la cual se van a mostrar los precios">
                    <b-form-select v-model="block.control.currencyDefault" :options="arr.select.currency"></b-form-select>
                  </b-form-group>          
                </b-col>                                                                    
              </b-row>

              <b-row>
                <b-col md="12" class="mt-2" v-if="showMessageForm">
                  <b-form-group label="Consultas" description="Formulario donde se guardarán las consultas sobre productos no disponible a la venta por el sitio web">
                    <b-form-select v-model="block.control.messageFormsID" :options="arr.select.forms"></b-form-select>
                  </b-form-group>          
                </b-col>                   
              </b-row>

              <b-row v-if="isCatalog">
                <b-col md="12">
                  <hr>
                </b-col>
                <b-col md="12">
                  <h5>Catálogo</h5>
                </b-col>                
                <b-col md="12">
                  <b-form-checkbox                  
                    v-model="block.control.showRetailsProductsWithStock"
                    switch
                  >
                    ¿Mostrar solo productos con stock?
                  </b-form-checkbox>                                    
                </b-col>    

                <b-col md="12">
                  <b-form-checkbox                  
                    v-model="block.control.showRetailsProductsWithPriceList"
                    switch
                  >
                    ¿Mostrar solo productos con lista de precios?
                  </b-form-checkbox>                                    
                </b-col>                    
              </b-row>

              <b-row v-if="canDeliveries">
                <b-col md="12">
                  <hr>
                </b-col>
                <b-col md="12">
                  <h5>Configuración Ventas Minorista</h5>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Retiros de pedidos">
                      <b-form-checkbox                  
                        v-model="block.control.showDeliveriesLocal"
                        switch
                      >
                        ¿Habilitar retiro por local comercial?
                      </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Cálculo del precio de envío">
                      <b-form-radio-group
                        v-model="block.control.deliveriesTariff"
                        :options="optDeliveriesTariff"
                        stacked
                      ></b-form-radio-group>               
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group label="Mínimo de compra envío gratis" description="Si la compra supera este monto, el envío es gratis. Si el valor es cero (0) no se aplica">
                    <b-form-input type="number"
                                  size="sm"
                                  v-model="block.control.min_purchase_free_shipping">
                    </b-form-input>                    
                  </b-form-group>
                </b-col>    
                <b-col md="12">                  
                  <b-form-group label="Formas de pago" description="Selecionar si se cobra solo por medios electrónicos y/o NO electrónicos">
                    <b-form-select v-model="block.control.typePayment" :options="arr.select.typePayment"></b-form-select>
                  </b-form-group>          
                </b-col>                 
                <b-col md="12">
                  <b-form-checkbox                  
                    v-model="block.control.showRetailsProductsWithStock"
                    switch
                  >
                    ¿Muestra solo productos con stock?
                  </b-form-checkbox>                                    
                </b-col>        
                <b-col md="12">
                  <b-form-checkbox                  
                    v-model="block.control.showRetailsProductsWithPriceList"
                    switch
                  >
                    ¿Mostrar solo productos con lista de precios?
                  </b-form-checkbox>                                    
                </b-col>                                                                  
                <b-col md="12">
                  <b-alert :show="canDeliveries" variant="info" class="mt-2">
                    Para configurar sus <b>locales comerciales</b> y <b>tarifarios de entrega</b>, ingrese en este 
                    <b><a href="javascript:void(0);" @click="goDeliveries()">link</a></b>
                  </b-alert>   

                  <b-alert :show="canDeliveries" variant="info" class="mt-2">
                    Para crear <b>cupones de descuentos</b>, ingrese en este 
                    <b><a href="javascript:void(0);" @click="goStoreDiscounts()">link</a></b>
                  </b-alert>   
                </b-col>         
              </b-row>

              <b-row v-if="isWholesaler">
                <b-col md="12">
                  <hr>
                </b-col>          
                <b-col md="12">
                  <h5>Configuración Ventas Mayorista</h5>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Carro de compras">
                      <b-form-checkbox                  
                        v-model="block.control.saveWithoutPrice"
                        switch
                      >
                        ¿Permite agregar productos sin precios?
                      </b-form-checkbox>
                      <b-form-checkbox                  
                        v-model="block.control.saveWithoutStock"
                        switch
                      >
                        ¿Permite agregar productos sin stock?
                      </b-form-checkbox>                
                  </b-form-group>            
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab title="Acceso Cliente" id="content_products_settings_access">
            <b-card-text>  
              <b-tabs pills card vertical>
                <b-tab title="Acceso 1" active>
                  <b-card-text>
                    <b-row>
                      <b-col lg="3">
                        <b-form-group label="Referencia" description="Texto sin espacios">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_ref_1">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="3">
                        <b-form-group label="Icono" description="Themify-Icons o Fontawesome">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_icon_1">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Nombre">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_name_1">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>  
                      <b-col lg="8">
                        <b-form-group label="URL" description="URL del archivo que se renderizará al click del acceso">
                          <b-form-input type="url"
                                        size="sm"
                                        v-model="block.control.access_url_1">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>    
                      <b-col lg="4">
                        <b-form-group label="Permiso"                                
                                      description="Elija que rol de usuario tiene permitido acceder a esta vista">                              
                          <b-form-select v-model="block.control.access_permission_1" :options="optRolesCustomers" size="sm"></b-form-select>      
                        </b-form-group>
                      </b-col>
                    </b-row>              
                  </b-card-text>
                </b-tab>

                <b-tab title="Acceso 2">
                  <b-card-text>
                    <b-row>
                      <b-col lg="3">
                        <b-form-group label="Referencia" description="Texto sin espacios">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_ref_2">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="3">
                        <b-form-group label="Icono" description="Themify-Icons o Fontawesome">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_icon_2">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Nombre">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_name_2">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>  
                      <b-col lg="8">
                        <b-form-group label="URL" description="URL del archivo que se renderizará al click del acceso">
                          <b-form-input type="url"
                                        size="sm"
                                        v-model="block.control.access_url_2">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>    
                      <b-col lg="4">
                        <b-form-group label="Permiso"                                
                                      description="Elija que rol de usuario tiene permitido acceder a esta vista">                              
                          <b-form-select v-model="block.control.access_permission_2" :options="optRolesCustomers" size="sm"></b-form-select>      
                        </b-form-group>
                      </b-col>                                            
                    </b-row> 
                  </b-card-text>
                </b-tab>
                
                <b-tab title="Acceso 3">
                  <b-card-text>              
                    <b-row>
                      <b-col lg="3">
                        <b-form-group label="Referencia" description="Texto sin espacios">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_ref_3">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="3">
                        <b-form-group label="Icono" description="Themify-Icons o Fontawesome">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_icon_3">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Nombre">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_name_3">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>  
                      <b-col lg="8">
                        <b-form-group label="URL" description="URL del archivo que se renderizará al click del acceso">
                          <b-form-input type="url"
                                        size="sm"
                                        v-model="block.control.access_url_3">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>    
                      <b-col lg="4">
                        <b-form-group label="Permiso"                                
                                      description="Elija que rol de usuario tiene permitido acceder a esta vista">                              
                          <b-form-select v-model="block.control.access_permission_3" :options="optRolesCustomers" size="sm"></b-form-select>      
                        </b-form-group>
                      </b-col>                                              
                    </b-row>                              
                  </b-card-text>
                </b-tab>
            
                <b-tab title="Acceso 4">
                  <b-card-text>
                    <b-row>
                      <b-col lg="3">
                        <b-form-group label="Referencia" description="Texto sin espacios">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_ref_4">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="3">
                        <b-form-group label="Icono" description="Themify-Icons o Fontawesome">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_icon_4">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Nombre">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.access_name_4">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>  
                      <b-col lg="8">
                        <b-form-group label="URL" description="URL del archivo que se renderizará al click del acceso">
                          <b-form-input type="url"
                                        size="sm"
                                        v-model="block.control.access_url_4">
                          </b-form-input>  
                        </b-form-group>                    
                      </b-col>  
                      <b-col lg="4">
                        <b-form-group label="Permiso"                                
                                      description="Elija que rol de usuario tiene permitido acceder a esta vista">                              
                          <b-form-select v-model="block.control.access_permission_4" :options="optRolesCustomers" size="sm"></b-form-select>      
                        </b-form-group>
                      </b-col>                                               
                    </b-row>               
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-tab>
          <b-tab title="Leyendas" id="content_products_settings_legend">
            <b-card-text>
              <b-tabs pills card vertical>
                <b-tab title="Lista Producto" active>
                  <b-card-text>              
                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="6">
                        <b-form-group label="Icono Botón Detalle Producto">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_button_icon_detail_product">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Texto Botón Detalle Producto">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_button_text_detail_product">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                                                                   
                    </b-row>
                  </b-card-text>
                </b-tab>
                <b-tab title="Detalle Producto">
                  <b-card-text>               
                    <b-row class="content_ecommerce_products_access">              
                      <b-col lg="12">
                        <b-form-group label='Despues de las imágenes del producto'>
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_products_similars">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                                                         
                      <b-col lg="12">
                        <b-form-group label='Productos Relacionados'>
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_products_related">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                                                                                 
                    </b-row>
                    <b-row class="content_ecommerce_products_access">              
                      <b-col lg="12">
                        <b-form-group label='Titulo para descarga y videos'>
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_title_download_videos_product_detail">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                             
                      <b-col lg="12">
                        <b-form-group label='Link de descargas'>
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_link_download_product_detail">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                           
                      <b-col lg="12">
                        <b-form-group label='Abrir Videos'>
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_videos_product_detail">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>         
                    </b-row>                    
                  </b-card-text>
                </b-tab>
                <b-tab title="Variedades">
                  <b-card-text>              
                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="6">
                        <b-form-group label="Color Singular" description="Al completar esta opción, se reemplaza el texto [Color]">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_variant_color">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Color Plural" description="Al completar esta opción, se reemplaza el texto [Colores]">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_variant_color_plural">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="6">
                        <b-form-group label="Talle Singular" description="Al completar esta opción, se reemplaza el texto [Talle]">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_variant_waist">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Talle Plural" description="Al completar esta opción, se reemplaza el texto [Talles]">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_variant_waist_plural">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                      
                    </b-row>
                  </b-card-text>                  
                </b-tab>                   
                <b-tab title="Compra">
                  <b-card-text>                                                 
                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="6">
                        <b-form-group label="Botón Finalizar Cabecera">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_button_header_finish">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Botón Finalizar Pedido">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_button_order_finish">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col> 
                    </b-row>

                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="12">
                        <b-form-group label="Aviso en carrito de compras">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_checkout_order">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                      
                    </b-row>            

                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="12">
                        <b-form-group label="Título [Pago NO Electrónico]">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_title_non_electronic">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                      
                      <b-col lg="12">
                        <b-form-group label="Descripción [Pago NO Electrónico]">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="block.control.legend_description_non_electronic">
                          </b-form-input>                    
                        </b-form-group>
                      </b-col>                                            
                    </b-row>           

                    <b-row class="content_ecommerce_products_access">
                      <b-col lg="12">
                        <b-form-group label="Medios de pagos en resumen de compra">
                          <Imagen :images="block.control.legend_summary_image_payment" 
                                  @load-image="block.control.legend_summary_image_payment = $event" 
                                  typeImage="legend_summary_image_payment"
                                  :multipleImage="false"
                                  :maxImage="1" 
                                  :compressImage="true"/>                          
                        </b-form-group>
                      </b-col>                      
                    </b-row>                                        
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-tab>
          <b-tab title="Renderizado">
              <b-row>
                <b-col lg="12">
                  <b-form-group label="Vista productos">
                      <b-form-checkbox                  
                        v-model="block.control.render_grid_product_category_top_movile"
                        switch>
                        ¿En dispositivos móviles, mostrar las categorías arriba de la grilla de producto?
                      </b-form-checkbox>

                      <b-form-checkbox                  
                        v-model="block.control.render_details_product_show_full_description"
                        switch>
                        ¿En detalle de productos, mostrar toda la descripción completa?
                      </b-form-checkbox>                      

                      <b-form-checkbox                  
                        v-model="block.control.render_hide_category"
                        switch>
                        Ocultar el filtro por categoría
                      </b-form-checkbox>

                      <b-form-checkbox                  
                        v-model="block.control.render_available_scroll_infinite"
                        switch>
                        Scroll Infinito en listdo de productos
                      </b-form-checkbox>                         

                      <b-form-checkbox                  
                        v-model="block.control.render_not_show_duplicate_product_by_name"
                        switch>
                        Mostrar solo una vez aquellos productos con el mismo nombre
                      </b-form-checkbox>                                               
                  </b-form-group>
                </b-col> 

                <b-col lg="12">
                  <b-form-group label="Despues de las imágenes del producto">
                    <b-form-select v-model="block.control.render_after_image_product" :options="optAfterImageProduct" size="sm"></b-form-select>      
                  </b-form-group>                  
                </b-col>

                <b-col lg="12">
                  <b-form-group label="Filtro inicial de productos">
                    <b-form-checkbox                  
                      v-model="block.control.render_grid_product_show_featured_products"                      
                      @input="changeFilterInitFeaturedProduct()"
                      switch>
                      ¿Mostrar solo productos destacados al ingresar al módulo desde el sitio web?
                    </b-form-checkbox>                                  
                  
                    <b-form-checkbox                  
                      v-model="block.control.render_grid_product_show_category_products"                      
                      @input="changeFilterInitCategoryProduct()"
                      switch>
                      ¿Mostrar una categoría al ingresar al módulo producto desde el sitio web?
                    </b-form-checkbox>                  
                    <b-form-group class="w-50 mt-1"
                                  v-if="String(block.control.render_grid_product_show_category_products).toLowerCase() == 'true'">
                      <b-form-select v-model="block.control.render_grid_product_selected_category_products" :options="optCategorys" size="sm"></b-form-select>      
                    </b-form-group>
                  </b-form-group>
                </b-col>

                <b-col lg="12">
                  <b-form-group label="Ordenamiento de los productos">
                    <b-form-select v-model="block.control.render_grid_sort_init_product" :options="optGridSortProducts" size="sm"></b-form-select>      
                  </b-form-group>
                </b-col>

                <b-col lg="12">
                  <b-form-group label="Cantidad Productos" description="Cantidad de productos que se van a visualizar por página">
                    <b-form-input type="text"
                                  size="sm"
                                  v-model="block.control.quantity_products_per_page">
                    </b-form-input>                    
                  </b-form-group>                    
                </b-col>
              </b-row>
          </b-tab>
          <b-tab title="Ventas">
            <b-row>
              <b-col>
                <b-form-group label="Limitación de ventas minorista en ciertos paises" 
                              description="Ingrese aquellos paises donde comercialziar sus productos (si no se completa, no se aplica ningún límite). Ingrese el código Alpha-2 del país">                  
                  <b-form-tags v-model="block.control.limit_sales_country"                                  
                                tag-variant="primary"
                                tag-pills
                                size="md"                                 
                                separator=" "                                 
                                placeholder="Ingresar paises. Ej: 'AR'">
                  </b-form-tags>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Número de Whatsapp" description="Luego de finalizada la transacción arma el detalle del pedido para forzar el envío por whatsapp">
                  <b-form-input type="text"
                                size="sm"
                                v-model="block.control.send_order_whatsapp">
                  </b-form-input>                    
                </b-form-group>
              </b-col>
            </b-row>                
            <hr>
            <b-alert show variant="info" class="mt-2">
              Para configurar sus <b>metodos de pagos</b>, ingrese en este 
              <b><a href="javascript:void(0);" @click="goPayments()">link</a></b>
            </b-alert>  
          </b-tab>        
          <b-tab title="Permisos">
            <b-row>
              <b-col md="12">
                <b-form-group label="Finalizar pedido" 
                              description="Elija que rol de usuario tiene permitida esta acción">                              
                  <b-form-select v-model="block.control.permission_finish_order" :options="optRolesCustomers" size="sm"></b-form-select>      
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Bloquear login" 
                              description="Elija que rol de usuario tiene bloqueada esta acción">                              
                  <b-form-select v-model="block.control.permission_login" :options="optRolesCustomers" size="sm" multiple></b-form-select>      
                </b-form-group>
              </b-col>                     
              <b-col md="12">
                <b-form-group label="Creación de Usuarios" 
                              description="Si se activa, bloquea la posibilidad de crear usuarios desde el sitio web">                              
                  <b-form-checkbox                  
                    v-model="block.control.permission_block_register_user"
                    switch
                  >
                    Bloquear la creación de usuarios desde el sitio web
                  </b-form-checkbox>                                    
                </b-form-group>
              </b-col>                
              <b-col md="12">
                <b-form-group label="Edición datos de perfil, facturación y entrega" 
                              description="Si se activa, bloquea la edición de los datos de perfil, facturación y entrega desde el sitio web">                              
                  <b-form-checkbox                  
                    v-model="block.control.permission_block_edit_info_profile_invoice_delivery"
                    switch
                  >
                    Bloquear la edición de los datos de perfil, facturación y entrega
                  </b-form-checkbox>                                    
                </b-form-group>
              </b-col>                                     
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.title"
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Subtítulo">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.subtitle"
                            placeholder="Ingresar un subtítulo">
              </b-form-input>
            </b-form-group>
          </b-col>            
          <b-col md="12">
            <b-form-group label="Color de Texto">
              <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>    
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group> 
          </b-col> 
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col>            
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>  

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col>           

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>
  import serviceAPI from './../services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'

  import Imagen from '@/components/inc/image/image'  
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {      
      PrismEditor,   
      Imagen,      
    },     
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},  
        render: false, 
        optTypeView: [
          { text: 'Sin Catálogo (Autentificación directa)', value: 'showLogin' },
          { text: 'Mostrar Catálogo', value: 'showCatalog' },
          { text: 'Mostrar Cátalogo con Precios', value: 'showPrice' }
        ],
        optTypeTransaction: [
          { text: 'Solo ventas mayoristas (B2B)', value: 'B2B', disabled: false, },
          { text: 'Solo ventas minoristas (B2C)', value: 'B2C', disabled: false, },
          { text: 'Ventas mayoristas y minoristas (B2B y B2C)', value: 'both', disabled: false, },
          { text: 'Sin Ventas', value: 'none', disabled: false, }
        ],
        optDeliveriesTariff: [
          { text: 'Tarifario Simple', value: 'simple', },
          { text: 'Tarifario Condicional', value: 'conditional', },
        ],
        optRolesCustomers: [],
        optCategorys: [],
        optGridSortProducts: [
          { text: 'Sin orden establecido', value: 'default', },          
          {
            label: 'CAMPO << FECHA DE CARGA >>',
            options: [
              { text: 'Ascendente por fecha de carga', value: 'date_load_asc', },
              { text: 'Descendente por fecha de carga', value: 'date_load_desc', },
            ]
          },
          {
            label: 'CAMPO << ORDEN >>',
            options: [
              { text: 'Ascendente por campo orden', value: 'order_asc', },
              { text: 'Descendente por campo orden', value: 'order_desc', },          
            ]
          },
          {
            label: 'CAMPO << PRECIO >>',
            options: [
              { text: 'Ascendente por campo precio', value: 'price_asc', },
              { text: 'Descendente por campo precio', value: 'price_desc', },          
            ]
          }          
        ],
        optAfterImageProduct: [
          { text: 'Productos similares', value: 'product_similar', },
          { text: 'Descripción extenedida', value: 'description_extended', },
        ],
        arr: {
          select: {
            forms: [],
            currency: [],
            typePayment: [
              { text: 'Electrónico', value: 'electronic', },
              { text: 'No Electrónico', value: 'non-electronic', },              
              { text: 'Todas las formas disponibles', value: 'all', },              
            ],
          }
        }         
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = {          
          title: '',
          typeView: 'showLogin',
          typeTransaction: 'B2B',
          showDeliveriesLocal: false,
          deliveriesTariff: 'simple',
          saveWithoutPrice: false,
          saveWithoutStock: false,
          min_purchase_free_shipping: 0,
          showRetailsProductsWithStock: true,
          showRetailsProductsWithPriceList: false,
          access_ref_1: '',
          access_icon_1: '',
          access_name_1: '',
          access_url_1: '',
          access_permission_1: '',
          access_ref_2: '',
          access_icon_2: '',
          access_name_2: '',
          access_url_2: '',
          access_permission_2: '',
          access_ref_3: '',
          access_icon_3: '',
          access_name_3: '',
          access_url_3: '',
          access_permission_3: '',
          access_ref_4: '',
          access_icon_4: '',
          access_name_4: '',
          access_url_4: '',
          access_permission_4: '',
          legend_button_icon_detail_product: 'fas fa-inbox',
          legend_button_text_detail_product: 'Consultar',          
          legend_button_header_finish: 'Finalizar',
          legend_button_order_finish: 'Finalizar',
          legend_title_download_videos_product_detail: 'Más Información',
          legend_link_download_product_detail: 'Descargar',
          legend_videos_product_detail: 'Ver Videos',
          legend_products_similars: 'Productos Similares',
          legend_products_related: 'Productos Relacionados',
          legend_checkout_order: '',
          legend_title_non_electronic: '',
          legend_description_non_electronic: '',
          legend_summary_image_payment: [],
          legend_variant_color: '',
          legend_variant_color_plural: '',
          legend_variant_waist: '',
          legend_variant_waist_plural: '',
          limit_sales_country: [],
          render_grid_product_category_top_movile: false,
          render_details_product_show_full_description: false,
          render_available_scroll_infinite: true,
          render_hide_category: false,
          permission_finish_order: '',
          permission_login: [],
          render_grid_product_show_featured_products: false,
          render_grid_product_show_category_products: false,
          render_grid_product_selected_category_products: 0,
          permission_block_register_user: false,
          send_order_whatsapp: '',
          permission_block_edit_info_profile_invoice_delivery: false,
          messageFormsID: 0,
          currencyDefault: '',
          render_grid_sort_init_product: 'default',
          showPriceWithIva: false,
          quantity_products_per_page: 9,    
          typePayment: 'electronic',
          render_after_image_product: 'product_similar',
          render_not_show_duplicate_product_by_name: false,
        },
        this.block.config = {
          title: '',
          subtitle: '',          
          fontColor: '#000000',
          backgroundColor: '#f5f5f5',
          backgroundImage: [],
          havePaddingBottom: true,
          havePaddingTop: true,  
          stylesCSS: '',        
        }
        this.block.images = ['legend_summary_image_payment'],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {                        
        var limit_sales_country = []
        if(this.dataBlock.control.limit_sales_country) {
          limit_sales_country = this.dataBlock.control.limit_sales_country.split(",")
        } 

        var img = []  
        var i = 0         
        var image = 1
        if(image) {          
          for (i; i < image; i++) {     
            if(this.dataBlock.control["legend_summary_image_payment_" + i]) {
              img.push({ path: this.dataBlock.control["legend_summary_image_payment_" + i] })
            }                              
          }          
        } 

        this.block.control= {
          title: 'Products',
          typeView: this.dataBlock.control.typeView,
          typeTransaction: this.dataBlock.control.typeTransaction,
          showDeliveriesLocal: this.dataBlock.control.showDeliveriesLocal,
          deliveriesTariff: this.dataBlock.control.deliveriesTariff,
          saveWithoutPrice: this.dataBlock.control.saveWithoutPrice,
          saveWithoutStock: this.dataBlock.control.saveWithoutStock, 
          min_purchase_free_shipping: this.dataBlock.control.min_purchase_free_shipping,
          showRetailsProductsWithStock: this.dataBlock.control.showRetailsProductsWithStock, 
          showRetailsProductsWithPriceList: this.dataBlock.control.showRetailsProductsWithPriceList, 
          access_ref_1: this.dataBlock.control.access_ref_1,
          access_icon_1: this.dataBlock.control.access_icon_1,
          access_name_1: this.dataBlock.control.access_name_1,
          access_url_1: this.dataBlock.control.access_url_1,
          access_permission_1: this.dataBlock.control.access_permission_1,
          access_ref_2: this.dataBlock.control.access_ref_2,
          access_icon_2: this.dataBlock.control.access_icon_2,
          access_name_2: this.dataBlock.control.access_name_2,
          access_url_2: this.dataBlock.control.access_url_2,
          access_permission_2: this.dataBlock.control.access_permission_2,
          access_ref_3: this.dataBlock.control.access_ref_3,
          access_icon_3: this.dataBlock.control.access_icon_3,
          access_name_3: this.dataBlock.control.access_name_3,
          access_url_3: this.dataBlock.control.access_url_3,
          access_permission_3: this.dataBlock.control.access_permission_3,
          access_ref_4: this.dataBlock.control.access_ref_4,
          access_icon_4: this.dataBlock.control.access_icon_4,
          access_name_4: this.dataBlock.control.access_name_4,
          access_url_4: this.dataBlock.control.access_url_4,   
          access_permission_4: this.dataBlock.control.access_permission_4,
          legend_button_icon_detail_product: this.dataBlock.control.legend_button_icon_detail_product,
          legend_button_text_detail_product: this.dataBlock.control.legend_button_text_detail_product,          
          legend_button_header_finish: this.dataBlock.control.legend_button_header_finish,
          legend_button_order_finish: this.dataBlock.control.legend_button_order_finish,
          legend_title_download_videos_product_detail: this.dataBlock.control.legend_title_download_videos_product_detail,
          legend_link_download_product_detail: this.dataBlock.control.legend_link_download_product_detail,          
          legend_videos_product_detail: this.dataBlock.control.legend_videos_product_detail, 
          legend_products_similars: this.dataBlock.control.legend_products_similars,
          legend_products_related: this.dataBlock.control.legend_products_related,
          legend_checkout_order: this.dataBlock.control.legend_checkout_order,
          legend_title_non_electronic: this.dataBlock.control.legend_title_non_electronic,
          legend_description_non_electronic: this.dataBlock.control.legend_description_non_electronic,
          legend_summary_image_payment: img,
          legend_variant_color: this.dataBlock.control.legend_variant_color,
          legend_variant_color_plural: this.dataBlock.control.legend_variant_color_plural,
          legend_variant_waist: this.dataBlock.control.legend_variant_waist,
          legend_variant_waist_plural: this.dataBlock.control.legend_variant_waist_plural,
          limit_sales_country: limit_sales_country,
          render_grid_product_category_top_movile: this.dataBlock.control.render_grid_product_category_top_movile,
          render_details_product_show_full_description: this.dataBlock.control.render_details_product_show_full_description,
          render_available_scroll_infinite: (this.dataBlock.control.render_available_scroll_infinite == undefined || this.dataBlock.control.render_available_scroll_infinite == 'true') ? 'true' : 'false',
          render_hide_category: this.dataBlock.control.render_hide_category,
          permission_finish_order: this.dataBlock.control.permission_finish_order,
          permission_login: this.dataBlock.control.permission_login ? this.dataBlock.control.permission_login.split(',') : [],
          render_grid_product_show_featured_products: this.dataBlock.control.render_grid_product_show_featured_products,
          render_grid_product_show_category_products: this.dataBlock.control.render_grid_product_show_category_products,          
          render_grid_product_selected_category_products: this.dataBlock.control.render_grid_product_selected_category_products,
          permission_block_register_user: this.dataBlock.control.permission_block_register_user,
          send_order_whatsapp: this.dataBlock.control.send_order_whatsapp,
          permission_block_edit_info_profile_invoice_delivery: this.dataBlock.control.permission_block_edit_info_profile_invoice_delivery,
          messageFormsID: this.dataBlock.control.messageFormsID,
          currencyDefault: this.dataBlock.control.currencyDefault,
          render_grid_sort_init_product: this.dataBlock.control.render_grid_sort_init_product,
          showPriceWithIva: this.dataBlock.control.showPriceWithIva,
          quantity_products_per_page: this.dataBlock.control.quantity_products_per_page,        
          typePayment: this.dataBlock.control.typePayment,
          render_after_image_product: this.dataBlock.control.render_after_image_product,
          render_not_show_duplicate_product_by_name: this.dataBlock.control.render_not_show_duplicate_product_by_name,
        }       

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {     
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }                              
          }          
        } 

        this.block.config= { 
          title: this.dataBlock.config.title,
          subtitle: this.dataBlock.config.subtitle,
          fontColor: this.dataBlock.config.fontColor,
          backgroundColor: this.dataBlock.config.backgroundColor,  
          backgroundImage: imgConfg,
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,         
          stylesCSS: this.dataBlock.config.stylesCSS, 
        }

        this.block.images = ['legend_summary_image_payment']
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    mounted() {      
      this.changeView(false)
      this.cargarRoles()
      this.cargarCategorys()
      this.loadForms()
      this.loadCurrency()
    },
    computed: {
      isCatalog() {
        if(this.block.control.typeView=="showCatalog") {
          return true
        } else {
          return false
        }
      },

      canDeliveries() {
        if(this.block.control.typeTransaction=="B2C" || 
           this.block.control.typeTransaction=="both") {
          return true
        } else {
          return false
        }
      },

      isWholesaler() {
        if(this.block.control.typeTransaction=="B2B" || 
           this.block.control.typeTransaction=="both") {
          return true
        } else {
          return false
        }        
      },

      showOptionCurrency() {
        if(this.block.control.typeView=="showCatalog" || this.block.control.typeView=="showPrice") {
          return true
        } else {
          return false
        }
      },

      showMessageForm(){
        return true        
      }
    },
    methods: {
      emit() {                
        this.$emit('get-object', this.block) 
      },      
      goProducts() {
        this.$router.push({ name: 'ProductsStaffCrudProducts' })
      },
      goDeliveries() {
        this.$router.push({ name: 'ContentsSttaffStoreDeliveries' })
      }, 
      goStoreDiscounts() {
        this.$router.push({ name: 'ContentsSttaffStoreDiscounts' })
      },
      goPayments() {
        this.$router.push({ name: 'PaymentsStaffDashboard' })
      },
      changeView(forceBlankSelection = true){        
        if(this.block.control.typeView == 'showLogin') {
          this.optTypeTransaction[0].disabled = false
          this.optTypeTransaction[1].disabled = true
          this.optTypeTransaction[2].disabled = true
          this.optTypeTransaction[3].disabled = true
        
          if(forceBlankSelection) {
            this.block.control.typeTransaction = 'B2B'

            this.block.control.showDeliveriesLocal = false
            this.block.control.deliveriesTariff = 'simple'
          }          
        }

        if(this.block.control.typeView == 'showCatalog') {
          this.optTypeTransaction[0].disabled = false
          this.optTypeTransaction[1].disabled = true
          this.optTypeTransaction[2].disabled = true
          this.optTypeTransaction[3].disabled = false

          if(forceBlankSelection) {
            this.block.control.typeTransaction = 'B2B'

            this.block.control.showDeliveriesLocal = false
            this.block.control.deliveriesTariff = 'simple'
          }          
        }

        if(this.block.control.typeView == 'showPrice') {
          this.optTypeTransaction[0].disabled = true
          this.optTypeTransaction[1].disabled = false
          this.optTypeTransaction[2].disabled = false
          this.optTypeTransaction[3].disabled = true

          if(forceBlankSelection) {
            this.block.control.typeTransaction = 'B2C'
          }          
        }
      },
      changeType(forceBlankSelection = true){        
        if(forceBlankSelection) {
          if(this.block.control.typeTransaction != 'B2B' ||
            this.block.control.typeTransaction != 'both') {
              
            this.block.control.saveWithoutPrice = false
            this.block.control.saveWithoutStock = false
          }
        }
      },
      changeFilterInitFeaturedProduct() {        
        if(this.dataBlock.control.render_grid_product_show_featured_products) {
          this.dataBlock.control.render_grid_product_show_category_products = false
          this.dataBlock.control.render_grid_product_selected_category_products = 0
        } 
      },
      changeFilterInitCategoryProduct() {        
        if(this.dataBlock.control.render_grid_product_show_category_products) {
          this.dataBlock.control.render_grid_product_show_featured_products = false
        } else {
          this.dataBlock.control.render_grid_product_selected_category_products = 0
        }        
      },
      cargarRoles () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerRoles()

        result.then((response) => {
          var data = response.data

          this.optRolesCustomers.push({ value: 0, text: '' })              
          data.forEach(element => {            
            if( element.profile_id == Profiles.CLIENTE ) {
              this.optRolesCustomers.push({ value: element.id, text: element.name })              
            }
          })

          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },  
      cargarCategorys() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerCategoriaConSubcategorias()

        result.then((response) => {
          var data = response.data

          data.forEach(element => {            
            this.optCategorys.push({ value: element.id, text: element.name })                          
          })
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });                
      },
      loadForms() {
        let loader = this.$loading.show();    
        var result = serviceAPI.obtenerForms()

        result.then((response) => {         
          var data = response.data
          
          data.forEach(element => {
            this.arr.select.forms.push({
              value: element.id,
              text: element.name
            })
          });
          
          loader.hide()   
        })
        .catch(error => {                   
          loader.hide()                 
          this.$awn.alert(Error.showError(error))
        });            
      },
      loadCurrency() {
        let loader = this.$loading.show();    
        var result = serviceAPI.obtenerMoneda()

        result.then((response) => {         
          var data = response.data
          
          this.arr.select.currency.push({
            value: '',
            text: 'Según el origen de la sesión'
          })

          data.forEach(element => {
            this.arr.select.currency.push({
              value: element.code,
              text: element.code
            })
          });
          
          loader.hide()   
        })
        .catch(error => {                   
          loader.hide()                 
          this.$awn.alert(Error.showError(error))
        });            
      }             
    }        
  }
</script>
<style scoped>
  .content_ecommerce_products_access {
    background: #f3f3f3;    
    margin: 5px;    
    padding-top: 5px;
    padding-bottom: 5px;    
  }
</style>
<style>
  #content_products_settings_access ul {
    padding: 5px;
  }
  #content_products_settings_legend ul {
    padding: 5px;
  }  
</style>