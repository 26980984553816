<template>
  <div>
    <b-row>
      <b-col>        
        <b-input-group-append v-for="(input, index) in listResult" :key="index">
          <b-form-input v-model="listResult[index]" size="sm"></b-form-input>                            
          <b-button variant="secondary" size="sm" title="Agregar" @click="addItem()" v-if="index==listResult.length - 1">
            <b-icon icon="plus" aria-hidden="true"></b-icon>
          </b-button>
          <b-button variant="outline-danger" size="sm" title="Quitar" @click="deleteItem(index)">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>          
        </b-input-group-append>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      list: {                
        required: true,            
      },    
      arrBlog: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        listResult: [],
      }
    }, 
    mounted() {
      this.getData()      
    },  
    watch: {
      listResult: function(newQuestion, oldQuestion) {            
        if(newQuestion.length) {
          this.$emit('load-list', JSON.stringify(newQuestion))          
        }        
      },
    },     
    methods: {  
      getData() {        
        if(this.list) {
          this.listResult = JSON.parse(this.list)
          return this.listResult
        } else {          
          if(!this.listResult.length) {
            this.listResult.push('')
            return this.listResult          
          }
        }          
      },
      addItem() {        
        this.listResult.push('')
      },
      deleteItem(index) {
        this.$bvModal.msgBoxConfirm('¿Desea borrar el item?', {
          title: 'Borrar Item',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {               
            if(this.listResult.length == 1) {
              this.$awn.alert("No se puede eliminar el item de categoría");
              return false
            }

            var valid = false
            this.arrBlog.forEach(element => {
              if(element.category == this.listResult[index]) {
                valid = true
              }
            });
            if(valid) {
              this.$awn.alert("No puede eliminar una categoria asignada a un item del blog.");
              return false
            }

            this.listResult.splice(index,1)
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })  
      }
    }
  }
</script>
