<template>
  <div v-if="mode=='edit'">
    <b-row v-if="render">
      <b-col lg="4" v-if="typeSlider!='video'">
        <b-row>
          <b-col lg="12">
            <b-form-group label="Imagen" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.control.image" 
                      @load-image="block.control.image = $event"                       
                      :typeImage="'Imagen_' + dataIndex + '_' + block.control.name "
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group>  
          </b-col>
        </b-row>
      </b-col>
      
      <b-col :class="{'col-lg-8':typeSlider!='video', 'col-lg-12':typeSlider=='video'}">      
        <b-row>
          <b-col lg="12">
            <b-form-checkbox v-model="block.control.active" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              Activo
            </b-form-checkbox>            
          </b-col>           

          <b-col lg="12" v-if="typeSlider=='video'">
            <b-form-group label="Video (MP4)">                                        
              <b-form-input type="url"
                            size="sm"
                            v-model="block.control.fileLink"                          
                            placeholder="Ingresar un link de video con formato .mp4">
              </b-form-input>              
            </b-form-group>            
          </b-col>

          <b-col lg="12">      
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.control.name"                          
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="12">
            <b-form-group label="Alineación" description="Alineación del contenido (titúlo, subtítulo y botón)">
              <b-form-select v-model="block.control.align" :options="arr.select.align"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12">
        <b-row>
          <b-col lg="10">      
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.control.title"                          
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>  
          <b-col lg="2">      
            <b-form-group label="Color">
              <b-form-input type="color"
                            size="sm"
                            v-model="block.control.titleColor">
              </b-form-input>
            </b-form-group>
          </b-col>   
        </b-row>
        <b-row>
          <b-col lg="12">      
            <b-form-group label="Subtítulo">
              <vue-editor v-model="block.control.subtitle"></vue-editor>
            </b-form-group>
          </b-col>            
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-form-group label="URL Botón" description="Si el botón no está habilitado, la URL del botón se activa con el click en la imagen">
          <b-form-input type="url"
                        size="sm"
                        v-model="block.control.button_url"                          
                        placeholder="Ingresar la url del botón">
          </b-form-input>
        </b-form-group>
      </b-col>                              
      <b-col lg="6">
        <b-form-group label="Texto Botón">
          <b-form-input type="text"
                        size="sm"
                        v-model="block.control.button_text"                          
                        placeholder="Ingresar el texto del botón">
          </b-form-input>
        </b-form-group>
      </b-col>            
    </b-row>
  </div>
  <div v-else>
    <div v-if="render">      
      <b-list-group-item class="flex-column align-items-start">

        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Item - {{block.control.name}}</h5>              
        </div>
      
      </b-list-group-item>    
    </div>
  </div>
</template>
<script>
  import Imagen from '@/components/inc/image/image'
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      Imagen,
      VueEditor
    },
    props:{
      dataBlock:{
        type: Object,
        default: {}
      },   
      dataIndex:{
        type: Number,
        default: 0,
        required: true,
      },             
      controlTemporal: {
        type: [Object, Array],
      },        
      typeSlider: {
        type: String,
        default: 'default'        
      },
      mode:{
        type: String,
        default: 'list'
      }         
    },
    data: () => {
      return {   
        block: {
          control: [],
        },        
        render: false,  
        arr: {
          select: {
            align: [
              {value: 'left', text: 'Izquierda' },              
              {value: 'center', text: 'Centro' },
              {value: 'right', text: 'Derecha' },
            ]
          }
        }                      
      }
    },
    created() {         
      if(!this.dataBlock) {
        this.block.control = { 
          active: false,
          name: '',
          align: '',
          title: '',
          titleColor: '',
          subtitle: '',
          button_url: '',
          button_text: '',        
          image: [],      
          fileLink: '',
        }
        
        this.emit()
        this.render = true
      }
      else {             
        var img = []  
        var i = 0                 
        if(this.getControlTermporal.image) {          
          for (i; i < this.getControlTermporal.image; i++) {        
            if(this.getControlTermporal["image_" + i]) {
              img.push({ path: this.getControlTermporal["image_" + i] })
            }
          }          
        }

        this.block.control= { 
          active: this.dataBlock.active,
          name: this.dataBlock.name,
          align: this.dataBlock.align,
          title: this.dataBlock.title,
          titleColor: this.dataBlock.titleColor,
          subtitle: this.dataBlock.subtitle,
          button_url: this.dataBlock.button_url,
          button_text: this.dataBlock.button_text,        
          image: img,            
          fileLink: this.dataBlock.fileLink,          
        }
        
        this.emit()
        this.render = true
      }
    },
    computed: {
      getControlTermporal() {
        if(!this.controlTemporal) {
          return this.dataBlock
        } else {
          return this.controlTemporal
        }        
      }
    },    
    methods: {
      emit() {        
        this.$emit('get-object', this.block.control) 
        this.$emit('get-object-control-temporal', this.getControlTermporal) 
      },
    }    
  }
</script>