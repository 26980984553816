<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-card header-tag="header" footer-tag="footer">
          <b-row>
            <b-col sm="7">
              <h4 id="traffic" class="card-title mb-0"><b>Página:</b> {{this.title.page}} - <b>Bloque:</b> {{this.title.block}}</h4>
              <div class="small text-muted">Configuración del bloque</div>
            </b-col>        
          </b-row>
        </b-card>

        <div v-if="pageBlock">          
          <BlockSimple v-if="isSimple" 
                       :reference="pageBlock.block.reference" 
                       :type="pageBlock.block.type"
                       :dataBlock="pageBlock" 
                       @save-object="save($event)"/>          

          <BlockMultiple v-if="isMultiple" 
                         :reference="pageBlock.block.reference" 
                         :type="pageBlock.block.type"
                         :dataBlock="pageBlock" 
                         @save-object="save($event)" 
                         @open-reorder="openReorder($event)"/>
        </div>
                    
      </b-col>
    </b-row>

    <!-- ORDER LIST -->
    <b-modal v-model="modal.widgetOrder.active"
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close>

      <div slot="modal-header">
        {{this.modal.widgetOrder.title}}
      </div>
      
      
      <b-list-group v-if="arr.items.control.length">
        <draggable
          :list="arr.items.control"
        >

          <b-list-group-item href="#"
                            class="flex-column align-items-start"
                            v-for="(element, key) in arr.items.control"
                            :key="key">

            <div class="d-flex w-100 justify-content-between">                            
              <h5 class="mb-1">Item - {{ (element.name ? element.name : (element.title ? element.title : Object.values(element)[0])) }} </h5>              
            </div>
          
          </b-list-group-item>

        </draggable>
      </b-list-group>
      <b-alert show v-else variant="warning" class="p-3 badge-dashboard-widget-config">No se encontraron items para ordenar</b-alert>
      
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-outline-secondary mr-1" @click="cancelReorder()">Cancelar</button>
        <button type="button" class="btn btn-dark" @click="save(arr.items)">Guardar</button>
      </template>
    </b-modal>
  </div>
  
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import {EventBus} from '@/handler/event-bus'
  import draggable from 'vuedraggable'
  import BlockSimple from './blocks/_simple'
  import BlockMultiple from './blocks/_multiple'
  
  export default { 
    components: {
      draggable,
      BlockSimple,
      BlockMultiple,
    },  
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPagesBlocksContents',
          elements: {}
        },  
        title: {
          page: '',
          block: '',          
        },
        modal: {          
          widgetOrder: {
            active: false,
            title: ''
          }
        },          
        arr: {
          items: {
            control: [],
            config: [],
            images: [],
            imagesConfig: [],
            multiple: false,
          }
        },         
        blockID: 0,  
        pageBlock: null   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.blockID = this.$route.params.blockID

      this.load()
    },
    computed: {
      isMultiple() {
        if(this.pageBlock.block.multiple) {
          return true
        } else {
          return false
        }     
      },
      isSimple() {
        if(!this.pageBlock.block.multiple) {
          return true
        } else {
          return false
        }      
      }
    },
    methods: {      
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.mostrarPaginaBloque(this.blockID)

        result.then((response) => {
          loader.hide()
          var data = response.data
          this.pageBlock = data
          this.title.page = data.page.name
          this.title.block = data.block.name
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },
      save(item) {
        this.$bvModal.msgBoxConfirm('¿Desea guardar el bloque?', {
          title: 'Guardar Bloque',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'CANCELAR',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {

            if(item.multiple) {              
              let loader = this.$loading.show();      

              var arrImagesMultiple = []
              item.control.forEach((element, index) => {
                var arrImages = new FormData();            
                item.images.forEach((value, key) => {                     
                  if(element[value].length){
                    arrImages.append(value, element[value][0].path);
                  } else {
                    arrImages.append(value, '');
                  }                  
                })  
                arrImagesMultiple.push(arrImages)
              });

              var arrImagesConfig = new FormData();            
              item.imagesConfig.forEach((value1, key1) => {   
                if(item.config[value1].length){           
                  arrImagesConfig.append(value1, item.config[value1][0].path);
                } else {
                  arrImagesConfig.append(value1, '');
                }
              })

              var result = serviceAPI.editarPaginaBloqueContentMultiple(this.blockID, JSON.stringify(item), arrImagesMultiple, arrImagesConfig);

              result.then((response) => {              
                loader.hide()                        
                this.$awn.success("Datos guardados con éxito");
                this.$router.go(-1)    
              })
              .catch(error => {
                loader.hide()
                this.$awn.alert(Error.showError(error));
              })  

            } else {              
              let loader = this.$loading.show();      
  
              var arrImages = new FormData();            
              item.images.forEach((value, key) => {   
                if(item.control[value].length){           
                  arrImages.append(value, item.control[value][0].path);
                } else {
                  arrImages.append(value, '');
                }
              })

              var arrImagesConfig = new FormData();            
              item.imagesConfig.forEach((value1, key1) => {   
                if(item.config[value1].length){           
                  arrImagesConfig.append(value1, item.config[value1][0].path);
                } else {
                  arrImagesConfig.append(value1, '');
                }
              })

              var result = serviceAPI.editarPaginaBloqueContentSimple(this.blockID, JSON.stringify(item), arrImages, arrImagesConfig);

              result.then((response) => {              
                loader.hide()                        
                this.$awn.success("Datos guardados con éxito");
                this.$router.go(-1)    
              })
              .catch(error => {
                loader.hide()
                this.$awn.alert(Error.showError(error));
              })            
            }
        
          }
        })        
      },

      openReorder(items) {        
        this.arr.items['control'] = items.control
        this.arr.items['config'] = items.config
        this.arr.items['images'] = items.images
        //this.arr.items['imagesConfig'] = items.imagesConfig
        this.arr.items['multiple'] = items.multiple          

        this.modal.widgetOrder.title = "Ordenar Items"
        this.modal.widgetOrder.active = true
      },      
      cancelReorder(){
        this.modal.widgetOrder.active = false
      }, 
    }    
  }
</script>