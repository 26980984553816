<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>   
          <b-col md="12">      

            <b-tabs content-class="mt-3">
              <b-tab title="General" active>
                <b-row>
                  <b-col lg="12">                
                    <b-form-group label="Descripción" description="Texto debajo del logo">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.description"
                                    placeholder="Ingresar un descripción">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group label="HTML" description="Se aplicará en la primer columna debajo de los teléfonos">
                      <prism-editor v-model="block.control.html" language="html" class="content-crud-block-css"></prism-editor>            
                    </b-form-group>
                  </b-col>     
                </b-row>             
              </b-tab>
              <b-tab title="Enlaces">
                <b-row>
                  <b-col>
                    <b-form-group label="Nombre 1">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.name1">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Enlace 1">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.link1">
                      </b-form-input>  
                    </b-form-group>                    
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group label="Nombre 2">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.name2">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Enlace 2">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.link2">
                      </b-form-input>  
                    </b-form-group>                    
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group label="Nombre 3">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.name3">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Enlace 3">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.link3">
                      </b-form-input>  
                    </b-form-group>                    
                  </b-col>
                </b-row>  

                <b-row>
                  <b-col>
                    <b-form-group label="Nombre 4">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.name4">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Enlace 4">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.link4">
                      </b-form-input>  
                    </b-form-group>                    
                  </b-col>
                </b-row>       

                <b-row>
                  <b-col>
                    <b-form-group label="Nombre 5">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.name5">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Enlace 5">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.link5">
                      </b-form-input>  
                    </b-form-group>                    
                  </b-col>
                </b-row>     

                <b-row>
                  <b-col>
                    <b-form-group label="Nombre 6">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.name6">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Enlace 6">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.link6">
                      </b-form-input>  
                    </b-form-group>                    
                  </b-col>
                </b-row> 
              </b-tab>
              <b-tab title="Instagram">
                <b-form-group label="Instagram" description="Ingrese su cuenta de instagram para mostrar el feed">
                  <b-form-input type="text"
                                size="sm"
                                v-model="block.control.instagram"
                                placeholder="Cuenta de Instagram">
                  </b-form-input>
                </b-form-group>
              </b-tab>
              <b-tab title="Firma Diseñador">
                <b-row>
                  <b-col>
                    <b-form-group label="Empresa">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="block.control.designBussinesName">
                      </b-form-input>                    
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Sitio Web">
                      <b-form-input type="url"
                                    size="sm"
                                    v-model="block.control.designBussinesSite">
                      </b-form-input>  
                    </b-form-group>                  
                  </b-col>
                </b-row>
              </b-tab>              
            </b-tabs>

          </b-col>          
        </b-row>
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>      
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group>  
          </b-col>   

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col>           

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>  
  import Imagen from '@/components/inc/image/image'
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default { 
    components: {     
      PrismEditor,    
      Imagen,
    },       
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},               
        render: false,        
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = { 
          description: '',
          html: '',
          name1: '',
          name2: '',
          name3: '',
          name4: '',
          name5: '',
          name6: '',
          link1: '',
          link2: '',
          link3: '',
          link4: '',
          link5: '',
          link6: '',
          instagram: '',         
          designBussinesName: '',
          designBussinesSite: '',                                   
        },
        this.block.config = {
          backgroundColor: '#f7f8fb',
          backgroundImage: [],
          stylesCSS: '',
        }
        this.block.images = [],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {         
        this.block.control= { 
          description: this.dataBlock.control.description,
          html: this.dataBlock.control.html,
          name1: this.dataBlock.control.name1,
          name2: this.dataBlock.control.name2,
          name3: this.dataBlock.control.name3,
          name4: this.dataBlock.control.name4,
          name5: this.dataBlock.control.name5,
          name6: this.dataBlock.control.name6,
          link1: this.dataBlock.control.link1,
          link2: this.dataBlock.control.link2,
          link3: this.dataBlock.control.link3,
          link4: this.dataBlock.control.link4,
          link5: this.dataBlock.control.link5,          
          link6: this.dataBlock.control.link6,          
          instagram: this.dataBlock.control.instagram,   
          designBussinesName: this.dataBlock.control.designBussinesName,
          designBussinesSite: this.dataBlock.control.designBussinesSite           
        }
        
        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {                        
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }   
          }          
        }        

        this.block.config= { 
          backgroundColor: this.dataBlock.config.backgroundColor,                                       
          backgroundImage: imgConfg,
          stylesCSS: this.dataBlock.config.stylesCSS,
        }

        this.block.images = []
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    methods: {
      emit() {        
        this.$emit('get-object', this.block) 
      },
    }        
  }
</script>