<template>
  <div>    

    <ImageTextRight v-if="reference=='image_with_text_right' && type!='custom'"  
               :dataBlock="block" 
               @get-object="loadData($event)"/>

    <ImageTextLeft v-if="reference=='image_with_text_left' && type!='custom'"  
               :dataBlock="block" 
               @get-object="loadData($event)"/>

    <OnlyText v-if="reference=='only_text' && type!='custom'"  
               :dataBlock="block" 
               @get-object="loadData($event)"/>                              

    <OnlyImage v-if="reference=='only_image' && type!='custom'"  
               :dataBlock="block" 
               @get-object="loadData($event)"/>   

    <CallToActionTwoButtons v-if="reference=='call_to_action_two_buttons' && type!='custom'"  
               :dataBlock="block" 
               @get-object="loadData($event)"/>   

    <PageHead v-if="reference=='page_head' && type!='custom'"  
              :dataBlock="block" 
              @get-object="loadData($event)"/>   

    <Products v-if="reference=='products' && type!='custom'"  
              :dataBlock="block" 
              @get-object="loadData($event)"/>   

    <ProductsFeatured v-if="reference=='products_featured' && type!='custom'"  
                      :dataBlock="block" 
                      @get-object="loadData($event)"/>                 

    <FormsContact v-if="reference=='forms_contact' && type!='custom'"  
                  :dataBlock="block" 
                  @get-object="loadData($event)"/> 

    <Form v-if="reference=='form' && type!='custom'"  
          :dataBlock="block" 
          @get-object="loadData($event)"/> 

    <ImagesGallery v-if="reference=='images_gallery' && type!='custom'"  
                   :dataBlock="block" 
                   @get-object="loadData($event)"/> 

    <FooterWebsite v-if="reference=='footer_website' && type!='custom'"  
                  :dataBlock="block" 
                  @get-object="loadData($event)"/>   

    <FooterEcommerce v-if="reference=='footer_ecommerce' && type!='custom'"  
                     :dataBlock="block" 
                     @get-object="loadData($event)"/>   

    <FeedInstagram v-if="reference=='feed_instagram' && type!='custom'"  
                     :dataBlock="block" 
                     @get-object="loadData($event)"/>   

    <Courses v-if="reference=='courses' && type!='custom'"  
              :dataBlock="block" 
              @get-object="loadData($event)"/>   

    <CoursesFeatured v-if="reference=='courses_featured' && type!='custom'"  
                      :dataBlock="block" 
                      @get-object="loadData($event)"/>                 

    <FooterCourses v-if="reference=='footer_courses' && type!='custom'"  
                     :dataBlock="block" 
                     @get-object="loadData($event)"/>   

    <Marketplace v-if="reference=='marketplace' && type!='custom'"  
                     :dataBlock="block" 
                     @get-object="loadData($event)"/>                 

    <FooterMarketplace v-if="reference=='footer_marketplace' && type!='custom'"  
                      :dataBlock="block" 
                      @get-object="loadData($event)"/>  
    
    <Contact v-if="reference=='contact' && type!='custom'"  
                  :dataBlock="block" 
                  @get-object="loadData($event)"/> 

    <CodeHtml v-if="reference=='code_html' && type!='custom'"  
                  :dataBlock="block" 
                  @get-object="loadData($event)"/> 

    <Custom v-if="type=='custom'"  
                  :dataBlock="block" 
                  :contentBlock="dataBlock"
                  @get-object="loadData($event)"/> 

    <b-card header-tag="header" footer-tag="footer" slot="footerBlocks">
      <b-row>
        <b-col>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>   

          <b-button type="button" variant="dark" class="pull-right" size="sm" @click="save()">
            Guardar
            <i class="fa fa-save"></i>
          </b-button>                      
        </b-col>        
      </b-row>
    </b-card>
  </div>
</template>
<script>
  import Error from '@/handler/error'  
  
  import ImageTextRight from './types/simple/website/_imageTextRight'      
  import ImageTextLeft from './types/simple/website/_imageTextLeft'    
  import OnlyText from './types/simple/website/_onlyText'
  import OnlyImage from './types/simple/website/_onlyImage'
  import CallToActionTwoButtons from './types/simple/website/_callToActionTwoButtons'
  import PageHead from './types/simple/website/_pageHead'
  import FormsContact from './types/simple/website/_formsContact'
  import Form from './types/simple/website/_form'
  import ImagesGallery from './types/simple/website/_imagesGallery'
  import FooterWebsite from './types/simple/website/_footerWebsite'
  import FeedInstagram from './types/simple/website/_feedInstagram'
  import Products from './types/simple/ecommerce/_products'
  import ProductsFeatured from './types/simple/ecommerce/_productsFeatured'
  import FooterEcommerce from './types/simple/ecommerce/_footerEcommerce'
  import Courses from './types/simple/courses/_courses'
  import CoursesFeatured from './types/simple/courses/_coursesFeatured'
  import FooterCourses from './types/simple/courses/_footerCourses'
  import Marketplace from './types/simple/marketplace/_marketplace'
  import FooterMarketplace from './types/simple/marketplace/_footerMarketplace'
  import Contact from './types/simple/website/_contact'
  import CodeHtml from './types/simple/website/_codeHtml'
  import Custom from './types/simple/_custom'

  export default {
    components: {
      ImageTextRight,    
      ImageTextLeft,    
      OnlyText,
      OnlyImage,
      CallToActionTwoButtons,
      PageHead,
      Products,
      ProductsFeatured,
      FormsContact,
      Form,
      ImagesGallery,
      FooterWebsite,
      FooterEcommerce,
      FeedInstagram,
      Courses,
      CoursesFeatured,
      FooterCourses,
      Marketplace,
      FooterMarketplace,
      Contact,
      CodeHtml,
      Custom
    },      
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },
      reference: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }                  
    },    
    data: () => {
      return {   
        block: {
          control: {},
          config: {},
          images: [],
          imagesConfig: [],
          multiple: false,
        },        
      }
    },  
    created () {      
      if(this.dataBlock.content) {              
        var dataBlock = JSON.parse(this.dataBlock.content)        
        this.block = dataBlock        
      } else {
        this.block.control = null
      }
    },
    methods:{
      loadData(item) {        
        this.block.control = item.control
        this.block.config = item.config
        this.block.images = item.images
        this.block.imagesConfig = item.imagesConfig
        this.block.multiple = false
      },
      save() {
        this.$emit('save-object', this.block) 
      }
    }
  }
</script>