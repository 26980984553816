<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>   
            <b-col lg="12">
              <b-form-group label="HTML">
                <prism-editor v-model="block.control.html" language="html" class="content-crud-block-html"></prism-editor>            
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group label="JavaScript">
                <prism-editor v-model="block.control.javascript" language="javascript" class="content-crud-block-js"></prism-editor>            
              </b-form-group>
            </b-col>            
        </b-row>
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>          
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col> 
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>  

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col> 

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>    
  import { VueEditor } from "vue2-editor";
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {  
    components: {    
      PrismEditor,           
      VueEditor
    },     
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},     
        render: false,        
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = { 
          html: '',          
          javascript: '',          
        },
        this.block.config = {          
          havePaddingBottom: true,
          havePaddingTop: true,
          stylesCSS: '',
        }

        this.block.images = [],
        this.block.imagesConfig = [],

        this.emit()
        this.render = true
      }
      else {                     
        this.block.control= { 
          html: this.dataBlock.control.html,
          javascript: this.dataBlock.control.javascript,
        }

        this.block.config= { 
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,
          stylesCSS: this.dataBlock.config.stylesCSS,
        }

        this.block.images = []
        this.block.imagesConfig = []

        this.emit()
        this.render = true
      }
    },
    methods: {
      emit() {        
        this.$emit('get-object', this.block) 
      },
    }        
  }
</script>
<style>
  .content-crud-block-html {
    height: 300px;
  }
  .content-crud-block-html pre{
    height: 300px;
  }  

  .content-crud-block-js {
    height: 200px;
  }
  .content-crud-block-js pre{
    height: 200px;
  }    
</style>