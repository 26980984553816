<template>
  <div v-if="mode=='edit'">
    <b-row v-if="render">
      <b-col md="4">
        <b-row>
          <b-col md="12">      
            <b-form-group label="Imagen">                          
              <Imagen :images="block.control.image" 
                      @load-image="block.control.image = $event" 
                      :typeImage="'Imagen_' + dataIndex + '_' + block.control.name "
                      :compressImage="true" />                       
            </b-form-group>  
          </b-col>
        </b-row>
      </b-col>
      <b-col md="8">
        <b-row>     
          <b-col md="12">      
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.control.name"                          
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>                
          <b-col md="12">      
            <v-select :options="arr.select.categorys" v-model="block.control.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
            <v-select :options="arr.select.subcategorys" v-model="block.control.subcategorys" placeholder="Subcategorías" :multiple="false" :select-on-tab="true"></v-select>
          </b-col> 
        </b-row>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div v-if="render">      
      <b-list-group-item class="flex-column align-items-start">

        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Item - {{block.control.name}}</h5>              
        </div>
      
      </b-list-group-item>    
    </div>
  </div>
</template>
<script>
  import Imagen from '@/components/inc/image/image'
  import serviceAPI from './../services'

  export default {
    components: {
      Imagen,
    },
    props:{
      dataBlock:{
        type: Object,
        default: {}
      },   
      dataIndex:{
        type: Number,
        default: 0,
        required: true,
      },       
      controlTemporal: {
        type: [Object, Array],
      },              
      mode:{
        type: String,
        default: 'list'
      }         
    },
    data: () => {
      return {   
        block: {
          control: [],
        },        
        render: false,   
        arr: {
          select: {
            data: [],
            categorys: [],
            subcategorys: [],
          }
        }
      }
    },
    created() {
      if(!this.dataBlock) {
        this.block.control = {           
          name: '',   
          image: [],     
          categorys: null,
          subcategorys: null, 
        }
        
        this.emit()
        this.render = true
      }
      else {             
        var img = []  
        var i = 0                 
        if(this.getControlTermporal.image) {          
          for (i; i < this.getControlTermporal.image; i++) {        
            if(this.getControlTermporal["image_" + i]) {
              img.push({ path: this.getControlTermporal["image_" + i] })
            }
          }          
        }

        this.block.control= {           
          name: this.dataBlock.name,       
          image: img,     
          categorys: this.dataBlock.categorys,
          subcategorys: this.dataBlock.subcategorys,                 
        }
        
        this.emit() 
        this.render = true
      }
    },
    computed: {
      getControlTermporal() {
        if(!this.controlTemporal) {
          return this.dataBlock
        } else {
          return this.controlTemporal
        }        
      }
    },  
    mounted() {
      this.filterLoad()
    },  
    methods: {
      filterLoad() {
        var result = serviceAPI.obtenerCategoriaConSubcategorias()        
        result.then((response) => {
          var data = response.data    
          this.arr.select.data = data

          this.filterLoadCategorys()   

          if(this.block.control.categorys) {
            this.filterLoadSubCategorys(this.block.control.categorys)
          }          
        });                             
      },
      filterLoadCategorys() {
        
        this.arr.select.data.forEach(element => {              
          this.arr.select.categorys.push({code: element.id, label: element.name})                               
        });                             
      },   
      filterLoadSubCategorys(val) {
        this.arr.select.subcategorys = []
        
        if(val) {                      
          this.arr.select.data.forEach(element => {          
            if(element.id == val.code) {
              element.subcategorys.forEach(element1 => {  
                this.arr.select.subcategorys.push({code: element1.id, label: element1.name})                   
              }); 
            }                                  
          });
        }
      },      
      emit() {        
        this.$emit('get-object', this.block.control) 
        this.$emit('get-object-control-temporal', this.getControlTermporal) 
      },
    }    
  }
</script>