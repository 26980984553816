<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>
          <b-col>
            <b-alert show variant="warning">
              La carga de cursos se realiza desde el módulo de cursos. 
              <b-link @click="goCourses()">Ir a cursos</b-link>
            </b-alert>
            <hr>
          </b-col>
        </b-row>  

        <b-tabs card>
          <b-tab title="Opciones" active>
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group label="Vista de resultado de búsqueda">

                      <b-form-checkbox                  
                        v-model="block.control.hidePriceInListSearch"
                        switch
                      >
                        ¿Ocultar precios en el listado de búsqueda?
                      </b-form-checkbox>
                      
                      <b-form-checkbox                  
                        v-model="block.control.hideCategoryInListSearch"
                        switch
                      >
                        ¿Ocultar categorías en el listado de búsqueda?
                      </b-form-checkbox>
                      
                  </b-form-group>   

                  <b-alert show variant="info" class="mt-2">
                    Para crear <b>cupones de descuentos</b>, ingrese a este 
                    <b><a href="javascript:void(0);" @click="goStoreDiscounts()">link</a></b>
                  </b-alert>                       
                </b-col>
              </b-row>     
            </b-card-text>
          </b-tab>
          <b-tab title="Videos">
            <b-card-text>
              <b-row>
                <b-col lg="6">
                  <b-form-group label="Contraseña para reproducción" description="En algunas plataformas de videos, permite agregar una contraseña de seguridad para su reproducción.">
                    <b-form-input type="text"
                                  size="sm"
                                  v-model="block.control.legend_pass_video">
                    </b-form-input>                    
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab title="Ventas">
              <b-row>
                <b-col>
                  <b-form-group label="Limitación de ventas en ciertos paises" 
                                description="Ingrese aquellos paises donde comercialziar sus cursos (si no se completa, no se aplica ningún límite). Ingrese el código Alpha-2 del país">                  
                    <b-form-tags v-model="block.control.limit_sales_country"                                  
                                 tag-variant="primary"
                                 tag-pills
                                 size="md"                                 
                                 separator=" "                                 
                                 placeholder="Ingresar paises. Ej: 'AR'">
                    </b-form-tags>
                  </b-form-group>
                </b-col>
              </b-row>
            <hr>
            <b-alert show variant="info" class="mt-2">
              Para configurar sus <b>metodos de pagos</b>, ingrese en este 
              <b><a href="javascript:void(0);" @click="goPayments()">link</a></b>
            </b-alert>  
          </b-tab>                              
        </b-tabs>
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.title"
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Subtítulo">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.subtitle"
                            placeholder="Ingresar un subtítulo">
              </b-form-input>
            </b-form-group>
          </b-col>            
          <b-col md="12">
            <b-form-group label="Color de Texto">
              <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>    
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group> 
          </b-col> 
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col>            
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>  

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col>           

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>
  import Imagen from '@/components/inc/image/image'  
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {      
      PrismEditor,   
      Imagen,      
    },     
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},  
        render: false,              
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = {          
          title: '',
          hidePriceInListSearch: false,
          hideCategoryInListSearch: false,
          legend_pass_video: '',
          limit_sales_country: '',
        },
        this.block.config = {
          title: '',
          subtitle: '',          
          fontColor: '#000000',
          backgroundColor: '#f5f5f5',
          backgroundImage: [],
          havePaddingBottom: true,
          havePaddingTop: true,  
          stylesCSS: '',        
        }
        this.block.images = [],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {                             
        var limit_sales_country = ''
        if(this.dataBlock.control.limit_sales_country) {
          limit_sales_country = this.dataBlock.control.limit_sales_country.split(",")
        }

        this.block.control= {
          title: 'Courses',
          hidePriceInListSearch: this.dataBlock.control.hidePriceInListSearch,
          hideCategoryInListSearch: this.dataBlock.control.hideCategoryInListSearch,
          legend_pass_video: this.dataBlock.control.legend_pass_video,
          limit_sales_country: limit_sales_country,
        }

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {     
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }                              
          }          
        } 

        this.block.config= { 
          title: this.dataBlock.config.title,
          subtitle: this.dataBlock.config.subtitle,
          fontColor: this.dataBlock.config.fontColor,
          backgroundColor: this.dataBlock.config.backgroundColor,  
          backgroundImage: imgConfg,
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,         
          stylesCSS: this.dataBlock.config.stylesCSS, 
        }

        this.block.images = []
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    methods: {
      emit() {                        
        this.$emit('get-object', this.block) 
      },      
      goCourses() {
        this.$router.push({ name: 'CoursesStaffCrudCourses' })
      },     
      goStoreDiscounts() {
        this.$router.push({ name: 'ContentsSttaffStoreDiscounts' })
      },      
      goPayments() {
        this.$router.push({ name: 'PaymentsStaffDashboard' })
      },
    }        
  }
</script>